.input {
    height: 40px;
    border-radius: 3px;
    border: 1px solid #D9D9D9;
    min-width: 360px;
    padding: 0 8px;
}

label {
    margin-top: 10px;
}

.textarea {
    height: auto;
    padding: 8px;
}