@import '_vars.css';

body {
    background: #FDFDFD;
    font-size: 14px;
    font-family: 'SF UI Text Regular', sans-serif;
    color: var(--color-black);
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.5px;
}

.rs-btn-red {
    background-color: #ac1f2d !important;
    color: #fff;
}

.rs-btn-red:hover {
    color: #fff;
}

input:focus, textarea:focus, select:focus, button:focus{
    outline: none;

}

select{
    width: 100%;
}

input[type=text]{
    width: 100%;
}

textarea{
    width: 100%;
}

.rs-table {
    border: 1px solid #E3E3E3;
    border-radius: 3px;
    cursor: pointer;
}

input:disabled, textarea:disabled, select:disabled {
    background: #fafafa;
}

.page-container{
    width: 100%;
}

.side-menu{
    width: 300px;
}

.side-menu ul{
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 5.5rem;
    width: 235px;
    position: fixed;
}


.side-menu ul li a{
    font-size: 1rem;
    color: #3F3F3F;
    display:block;
    border-radius: .5rem;
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    text-decoration: none;
    text-align: left;
}

.side-menu ul li a.active{
    font-weight: bold;
    background: #e5e5ea;
}

.status-indicator{
    display: flex;
    margin-right: 5px;
    align-items: center;
}

.status-indicator span{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.status-indicator span.green{
    background: green;
}

.status-indicator span.yellow{
    background: orange;
}

.checkbox-wrapper{
    padding: .2rem 0;
    float: left;
    width: 50%;
}

.checkbox-wrapper *{
    cursor: pointer !important;
}

.checkbox-group-heading{
    font-size: 1.2rem;
}

.checkbox-wrapper input[type=checkbox]{
    margin-right: .5rem;        
}

.tox-notification--warning{
    display: none !important;
}